import { graphql } from 'gatsby';
import * as React from 'react';

import { DeleteMetricaAccount, Layout } from '../components';
import { LanguageContext } from '../context/LanguageContext';
import { formatInfoLinks, formatMenuLinks } from '../utils/helpers';
import {
  IAllContentfulCategory,
  IContentfulVacationBanner,
} from '../utils/types';

interface IDeleteMetricaAccountProps {
  data: {
    contentfulVacationBanner?: IContentfulVacationBanner;
    allContentfulCategory: IAllContentfulCategory;
    allContentfulPrivacyAndToS: {
      nodes: {
        title: string;
        url: string;
      }[];
    };
  };
}

const DeleteMetricaAccountPage: React.FunctionComponent<IDeleteMetricaAccountProps> = ({
  data,
}) => {
  const menuLink = formatMenuLinks(data.allContentfulCategory.nodes, 'De');
  const infoLink = formatInfoLinks(data.allContentfulPrivacyAndToS.nodes, 'De');

  return (
    <LanguageContext.Provider value={'De'}>
      <Layout
        links={menuLink}
        infoLinks={infoLink}
        vacationBanner={data.contentfulVacationBanner}
      >
        <DeleteMetricaAccount />
      </Layout>
    </LanguageContext.Provider>
  );
};

export const query = graphql`
  {
    allContentfulCategory(
      sort: { fields: order }
      filter: { language: { eq: "De" } }
    ) {
      nodes {
        ...category
      }
    }
    allContentfulPrivacyAndToS(filter: { language: { eq: "De" } }) {
      nodes {
        title
        language
        url: slug
      }
    }
    contentfulVacationBanner {
      ...vacationBanner
    }
  }
`;

export default DeleteMetricaAccountPage;
